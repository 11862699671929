import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SanityTexte from "./SanityTexte";
import LinkFadeLeft from "./ui/TransitionLinks/LinkFadeLeft";
import { _linkResolver } from "../core/utils";

const query = graphql`
  query {
    footer: sanityFooter {
      credits: _rawCredits
      nav {
        ... on SanityInternalLink {
          _type
          label
          link {
            ... on SanityPageModulaire {
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityExternalLink {
          _type
          url: link
          label
        }
      }
    }
  }
`;

const Footer = () => {
  // const {
  //   footer: { credits },
  // } = useContext(WrapperContext);
  const {
    footer: { credits, nav },
  } = useStaticQuery(query);

  return (
    <footer className='text-sm p-sm md:p-md '>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-sm md:gap-md'>
        <div className='footer-item col-span-2'>
          <nav>
            <ul className='columns-2 md:gap-x-md'>
              {nav.map((li, i) => (
                <li key={i} className='menu-item '>
                  {li._type === "internalLink" ? (
                    <LinkFadeLeft to={_linkResolver(li.link)}>
                      {li.label}
                    </LinkFadeLeft>
                  ) : (
                    <a
                      href={li.url}
                      target='_blank'
                      rel='nooponer, noreferrer'
                      className=''>
                      {li.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className='footer-item copy '>
          ©Alix Lacloche
          <br />
          All rights reserved
        </div>
        <div className='footer-item '>
          <SanityTexte input={credits} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
