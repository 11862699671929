/* global Snipcart:false */
import React from "react";
import useShop from "./ShopContext";
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
// import { _localizeText } from "../../core/utils"
import PanierSvg from "../../images/panier.inline.svg";
import clsx from "clsx";
import styled from "styled-components";

const Button = styled.button`
  svg {
    width: 30px;
    path {
      /* fill: red; */
    }
  }
`;

const Cart = () => {
  const { cart } = useShop();
  const hasItemsInCart = cart && cart.items.count > 0;

  return (
    <div
      className={clsx(
        "cart md:uppercase pl-sm transition-all"
        // hasItemsInCart
        //   ? "translate-x-0 opacity-1"
        //   : "translate-x-full opacity-0"
      )}>
      <Button className='snipcart-checkout flex'>
        <div
          className='flex items-center button-inner'
          style={{
            transform: "translateY(-.1em)",
          }}>
          {/* <span>Panier</span> */}
          <PanierSvg />
          {/* {hasItemsInCart && (
            <span className='snipcart-items-count-- pl-xs'>
              ({cart.items.count})
            </span>
          )} */}
        </div>
        {/* <div
          className='sm-only '
          style={{
            transform: "translateY(.1em)",
          }}>
          <PanierSvg />
        </div> */}
      </Button>
    </div>
  );
};

export default Cart;
