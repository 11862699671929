import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";
import LinkFadeRight from "./ui/TransitionLinks/LinkFadeRight";
import LinkFadeLeft from "./ui/TransitionLinks/LinkFadeLeft";
import { _linkResolver } from "../core/utils";
import Cart from "./shop/Cart";
import { useMemo } from "react";
// import Menu from "./Menu";

const query = graphql`
  query {
    header: sanityHeader {
      siteName
    }
    menu: sanityMenu(location: { eq: "header" }) {
      title
      nav {
        _type
        label
        link {
          ... on SanityPageModulaire {
            _type
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const Header = () => {
  const {
    header: { siteName },
    menu: { nav },
  } = useStaticQuery(query);

  const hasShop = useMemo(() => {
    const exist = nav.filter((el) => {
      // console.log(el);
      return el.label.toLowerCase() === "shop";
    });
    return exist;
  }, [nav]);

  // console.log(hasShop);

  return (
    <header className={clsx("fixed z-10 p-_md md:p-md lowercase")}>
      <div className='flex flex-wrap md:justify-center items-baseline'>
        {/* <div className='site-name md:uppercase-'>
          <LinkFadeRight to='/'>
            <div className='inner relative flex flex-nowrap '>
              <span className='whitespace-nowrap regular'>{`${siteName},`}</span>
              <span className='whitespace-nowrap italic absolute left-0 opacity-0'>{`${siteName},`}</span>
            </div>
          </LinkFadeRight>
        </div> */}

        <nav>
          <ul className='flex items-baseline'>
            <li className='menu-item md:uppercase-'>
              <div className='site-name '>
                <LinkFadeRight to='/'>
                  <div className='inner relative  '>
                    <span className='whitespace-nowrap'>{`${siteName},`}</span>
                    {/* <span className='whitespace-nowrap italic absolute left-0 opacity-0'>{`${siteName},`}</span> */}
                  </div>
                </LinkFadeRight>
              </div>
            </li>
            {nav.map((li, i) => (
              <li key={i} className='menu-item md:uppercase-'>
                {li._type === "internalLink" ? (
                  <LinkFadeLeft
                    to={_linkResolver(li.link)}
                    className=''
                    title={li.label}>
                    <div className='inner relative'>
                      <span className='whitespace-nowrap'>{`${li.label}${
                        i < nav.length - 1 ? "," : ""
                      }`}</span>
                    </div>
                  </LinkFadeLeft>
                ) : (
                  <a
                    href={li.link}
                    target='_blank'
                    rel='nooponer, noreferrer'
                    className=''>
                    <span className='regular'>{li.label}</span>
                    <em className='italic absolute'>{li.label}</em>
                  </a>
                )}
              </li>
            ))}
            {/* <li className='sm-only absolute right-0'>
              <Cart />
            </li> */}
          </ul>
        </nav>
        {hasShop.length > 0 && (
          <div className='fixed right-0 bottom-0- top-0 hidden-sm- py-md px-sm z-50'>
            <Cart />
          </div>
        )}
        {/* <Menu /> */}
      </div>
    </header>
  );
};

export default Header;
