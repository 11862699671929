/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react";
import Helmet from "react-helmet";
import PubSub from "pubsub-js";
import Layout from "./src/components/Layout";
// import { AuthWrapper } from "./src/components/ui/authWall/AuthWrapper";

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script src='https://unpkg.com/pace-js@1.0.2/pace.min.js'></script>
        <script src='https://cdn.rawgit.com/progers/pathseg/master/pathseg.js'></script>
        <script src='https://cdn.jsdelivr.net/npm/poly-decomp@0.2.1/build/decomp.min.js'></script>
      </Helmet>
      {element}
    </>
  );
};

const _wrapPageElement = ({ element, props }) => {
  // console.log(hasPassword);
  return <Layout {...props}>{element}</Layout>;
};

const _onClientEntry = () => {
  const isTouch = "ontouchstart" in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add("touch");
  } else {
    document.documentElement.classList.add("no-touch");
  }
};

const _onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish("ROUTE_UPDATE");
};

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate };
