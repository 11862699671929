import React, { useEffect } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
// import ScreenSaverImage from "./ui/ScreenSaverImage";
// import DesignSystem from "./ui/DesignSystem";
import { ShopWrapper } from "./shop/ShopContext";
import Auth from "./ui/authWall/Auth";
// import useAuth, { AuthWrapper } from "./ui/authWall/AuthWrapper";

const Layout = ({ location, children, pageContext }) => {
  // console.log(pageContext);
  // const { hasPassword } = pageContext;

  useEffect(() => {
    _format();
    window.addEventListener("resize", _format);
    // if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true)

    return () => {
      window.removeEventListener("resize", _format);
    };
  }, []);

  const _format = () => {
    const wh = window.innerHeight;

    document.documentElement.style.setProperty("--app-height", wh + "px");

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    );

    document.documentElement.style.setProperty(
      "--main-height",
      wh - headerBounding.height + "px"
    );
  };

  // const { granted } = useAuth();
  // console.log({ granted });
  // const showContent = granted;
  return (
    <div id='page'>
      <ShopWrapper>
        <Header />
        {/* <DesignSystem /> */}
        <main className='z-10 bg-white'>{children}</main>
        {/* <main className='z-10 bg-white'>{children}</main> */}
        <Footer />

        {/* <ScreenSaverImage /> */}
        {/* {!granted && <Auth />} */}
      </ShopWrapper>
    </div>
  );
};

export default Layout;
