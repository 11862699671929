import React, { createContext, useState, useContext, useEffect } from "react";
import PubSub from "pubsub-js";

const ShopContext = createContext();
const initialState = {
  ready: false,
  userStatus: "SignedOut",
  cartQuantity: 0,
  cartTotal: 0,
  cartSubTotal: 0,
  products: [],
};

export const ShopWrapper = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [customer, setCustomer] = useState();
  const [cart, setCart] = useState();
  const [products, setProducts] = useState([]);
  // const [Shop, dispatchShop] = useState(initialState);

  useEffect(() => {
    const { Snipcart } = window;
    if (!Snipcart) {
      document.addEventListener("snipcart.ready", (e) => {
        setReady(true);
      });
    } else {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (!ready) return;
    // Snipcart.api.configure("show_cart_automatically", false)
    // console.log(Snipcart)
    const listenSnipcart = () => {
      const { customer, cart } = Snipcart.store.getState();
      // console.log(Snipcart.store.getState().cart.items.items)
      setCustomer(customer);
      setUserStatus(customer.status);
      setCart(cart);

      Snipcart.events.on("item.adding", (item, items) => {
        // console.log("item.adding", item);
        PubSub.publish("item.adding", item);
      });
      Snipcart.events.on("item.added", (cartItem) => {
        console.log("item.added", cartItem);
        PubSub.publish("item.added", cartItem);
      });
      // get quantity in cart
      // changed after v 3.0.12
      // const items =
      //   cart.items.length !== undefined ? cart.items : cart.items.items;
      // dispatch({
      //   type: "setQuantity",
      //   payload: items.reduce((total, item) => total + item.quantity, 0),
      // });
      // // connected or not
      // dispatch({
      //   type: "setUserStatus",
      //   payload: customer.status,
      // });
      // // connected or not
      // dispatch({
      //   type: "setTotal",
      //   payload: cart.total,
      // });
      // // connected or not
      // dispatch({
      //   type: "setSubTotal",
      //   payload: cart.subtotal,
      // });
    };
    // listen store update
    const unsubscribe = Snipcart.store.subscribe(listenSnipcart);
    // call first
    listenSnipcart();
    return () => unsubscribe();
  }, [ready]);

  return (
    <ShopContext.Provider
      value={{ ready, userStatus, customer, cart, products, setProducts }}>
      {children}
    </ShopContext.Provider>
  );
};
export default function useShop() {
  return useContext(ShopContext);
}
