import React, { useState, useRef } from "react";
import { useEffect } from "react";
import useAuth from "./AuthWrapper";

const Auth = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const inputRef = useRef();

  const { setGranted } = useAuth();

  const _onSubmit = async (evt) => {
    evt.preventDefault();
    // console.log('submit')
    setLoading(true);

    const body = { password: password };

    fetch("/api/auth", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((e) => e.json())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    console.log(data);
    if (!data) return;
    setGranted(data.granted);
  }, [data]);

  return (
    <div className='fixed w-screen h-screen inset-0 bg-white z-50'>
      <div className='flex justify-center items-center h-full'>
        <div>
          <p>In order to view this page please fill the password field</p>
          <form onSubmit={_onSubmit}>
            <div className='flex'>
              <div className='form-field'>
                <input
                  ref={inputRef}
                  type='text'
                  name='password'
                  placeholder='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onMouseEnter={() => inputRef.current.focus()}
                />
              </div>
              <div className='form-field'>
                {!loading && <button type='submit'>Go</button>}
                {loading && <span>Loading ...</span>}
              </div>
              {error && <p className='error'>{error.responseBody}</p>}
            </div>
          </form>
          {data && <div>{data.responseBody}</div>}
        </div>
      </div>
    </div>
  );
};

export default Auth;
