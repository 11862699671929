import React, { useCallback } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import "./animations.css";

export const LinkFadeRight = ({ children, to }) => {
  const length = 0.4;

  const exitTransition = {
    length: length,
    zIndex: 2,
    trigger: ({ node }) => {
      exitTransition.exitTrigger(node);
      if (node) node.style.top = -window.pageYOffset + "px";
      window.scrollTo({ top: -window.pageYOffset });
    },
    exitTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeRightOut 0.6s cubic-bezier(0, 0.47, 0, 1.01) forwards;"
      );
    }, []),
  };

  const entryTransition = {
    // appearAfter: 2,
    // length: 1,
    length: length,
    delay: length / 2,
    zIndex: 1,
    trigger: ({ node }) => {
      entryTransition.entryTrigger(node);
    },
    entryTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeRightIn 0.6s cubic-bezier(0, 0.47, 0, 1.01) forwards;"
      );
    }, []),
  };

  return (
    <TransitionLink to={to} exit={exitTransition} entry={entryTransition}>
      {children}
    </TransitionLink>
  );
};

export default LinkFadeRight;
