export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
export function pad2(n) {
  return n < 10 ? `0${n}` : n;
}

export function _linkResolver(doc) {
  // console.log(doc);
  if (!doc) return "/";

  switch (doc._type) {
    case "work":
      return `/work/${doc.slug.current}`;
    case "product":
      return `/product/${doc.slug.current}`;

    default:
      return `/${doc.slug.current}`;
  }
}
